import { device } from "src/styles/breakpoints"
import { grayscale, white } from "src/styles/colors"
import { primary } from "src/styles/newColors"
import styled from "styled-components"

export const LogoHeader = styled.header`
  max-width: 100%;
  min-height: 112px;
  background: ${grayscale[500]};
  padding: 24px 0;
  display: flex;
  justify-content: center;
`

export const Container = styled.div`
  display: flex;
  justify-content: center;

  @media ${device.desktopLG} {
    justify-content: start;
  }
`

export const SectionAbrirContaInterOne = styled.section`
  min-height: 1469px;
  background: ${grayscale[500]};
  padding: 40px 0;

  @media ${device.tablet} {
    min-height: 1110px;
  }
  @media ${device.desktopLG} {
    min-height: 810px;
  }
  @media ${device.desktopXL} {
    min-height: calc(100vh - 112px);
  }
`

export const ImageCartao = styled.div`
  display: flex;
  justify-content: center;
`

export const H1Title = styled.h1`
  font-family: 'Citrina', Helvetica, sans-serif;
  color: ${white};
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 24px;
  font-weight: 400;
  padding-left: 20px;
  padding-right: 20px;
  
  @media ${device.tablet} {
    font-size: 56px;
    line-height: 61.6px;
    text-align: left;
    padding-right: 40px;
    padding-left: 0;
  }
  @media ${device.desktopLG} {
    padding-right: 70px;
  }
  @media ${device.desktopXL} {
    padding-right: 145px;
  }
`

export const ListOfBenefits = styled.ul`
  list-style: none;
  margin: 24px 0;

  @media ${device.desktopLG} {
    margin: 56px 0;
  }
  @media ${device.desktopXL} {
    margin: 32px 0;
  }

  li {
    padding: 16px 0;
    font-size: 14px;
    line-height: 17px;
    color: ${primary[500]};
    border-top: 1px solid ${grayscale[300]};
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:last-child {
      border-bottom: 1px solid ${grayscale[300]};
    }
  }
`

export const TextDescription = styled.div`
  margin: 0 auto;
  padding: 0;

  span {
    @media ${device.desktopXL} {
     display: block;
    }
  }
  
  p {
    font-family: 'Citrina', Helvetica, sans-serif;
    font-size: 24px;
    line-height: 26.4px;
    color: ${white};
    text-align: center;
    margin-bottom: 40px;
  }
`
