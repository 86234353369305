import { Link } from "gatsby"
import React from "react"
import * as S from "./styles"

import ContatoEspecialistaInterOneAndBlack from "src/components/UI/Forms/ContatoEspecialistaInterOneAndBlack"
import Layout from 'src/layouts/BaseLayout'

import useWidth from "src/hooks/window/useWidth"
import { WIDTH_MD } from "src/styles/breakpoints"

import IconDuoGourmet from './assets/images/ic-fork-knife.svg'
import IconSalaVip from './assets/images/ic-plane-departure.svg'
import iconAtendimentoOrange from './assets/images/icon-atendimento-orange.svg'
import iconCardOrange from './assets/images/icon-card-orange.svg'
import iconCondicoesTaxasOrange from './assets/images/icon-condicoes-taxas-orange.svg'
import iconHeartOrange from './assets/images/icon-heart-orange.svg'
import LogoInteOne from './assets/images/logo-Inter-black.svg'

import pageContext from './pageContext.json'

const CartaoInterBlack = 'https://central-imagens.bancointer.com.br/images-without-small-versions/cartoes-lp-form-black/image.webp'

const dataBenefitsList = [
  {
    title: "Assessoria para investir",
    icon: iconHeartOrange,
  },
  {
    title: "Cartão Black sem anuidade",
    icon: iconCardOrange,
  },
  {
    title: "Atendimento especializado",
    icon: iconAtendimentoOrange,
  },
  {
    title: "Sala Vip",
    icon: IconSalaVip,
  },
  {
    title: "Duo Gourmet",
    icon: IconDuoGourmet,
  },
  {
    title: "Condições e taxas exclusivas",
    icon: iconCondicoesTaxasOrange,
  },
]

const AbrirContaInterOne = () => {
  const width = useWidth()

  return (
    <Layout pageContext={pageContext}>
      <S.LogoHeader>
        <div className='container'>
          <div className='row'>
            <Link
              to='/'
              title='Home Inter Co'
            >
              <img
                src={LogoInteOne}
                alt='Logo Inter Black'
                width={133}
                height={60}
              />
            </Link>
          </div>
        </div>
      </S.LogoHeader>
      <S.SectionAbrirContaInterOne>
        <S.Container className='container'>
          <div className='row'>
            <div className='col-lg-7 pl-lg-0'>
              <div className='row'>
                <div className='col-12 col-md-8 pr-md-0'>
                  <S.H1Title>Benefícios exclusivos no <strong>Inter Black</strong></S.H1Title>
                  {
                    width < WIDTH_MD &&
                      <S.ImageCartao>
                        <img
                          src={CartaoInterBlack}
                          alt='Logo Inter One'
                          width={232}
                          height={201}
                        />
                      </S.ImageCartao>
                  }
                </div>
                {
                  width >= WIDTH_MD &&
                    <div className='col-12 col-md-4'>
                      <S.ImageCartao>
                        <img
                          src={CartaoInterBlack}
                          alt='Logo Inter One'
                          width={width === WIDTH_MD ? 232 : 212}
                          height={width === WIDTH_MD ? 201 : 183}
                        />
                      </S.ImageCartao>
                    </div>
                }
              </div>
              <div className='col-12 px-md-0'>
                <S.ListOfBenefits>
                  {
                    dataBenefitsList.map((benefit: { title: string; icon: string }, index: number) => (
                      <li key={index}>
                        {benefit.title}
                        <img
                          src={benefit.icon}
                          alt='Um ícone representando um benefício do cartão Inter'
                          width={width < WIDTH_MD ? 16 : 24}
                          height={width < WIDTH_MD ? 16 : 24}
                        />
                      </li>
                    ))
                  }
                </S.ListOfBenefits>
                <S.TextDescription className='col-md-8 col-lg-12'>
                  <p><span>Com uma carteira de investimentos a partir de R$ 250 mil,</span> você se torna um cliente Inter Black</p>
                </S.TextDescription>
              </div>
              {
                width <= WIDTH_MD &&
                  <div className='col-12 col-lg-6 px-0'>
                    <ContatoEspecialistaInterOneAndBlack
                      page='interBlack'
                      lead='lead-lp-black'
                      customOrigin='MIDIA_PAGA_BLACK_SDR'
                      sectionName='Benefícios exclusivos no Inter Black'
                    />
                  </div>
              }
            </div>
            {
              width > WIDTH_MD &&
                <div className='col-lg-5 pl-lg-4'>
                  <ContatoEspecialistaInterOneAndBlack
                    page='interBlack'
                    lead='lead-lp-black'
                    customOrigin='MIDIA_PAGA_BLACK_SDR'
                    sectionName='Benefícios exclusivos no Inter Black'
                  />
                </div>
            }
          </div>
        </S.Container>
      </S.SectionAbrirContaInterOne>
    </Layout>
  )
}

export default AbrirContaInterOne
